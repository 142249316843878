import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import {
  RegisterAlLpSellerPayload,
  RegisterCityLpSellerPayload,
  RegistreLpSellerResponse,
  TokenValidity,
  UpdateLeasebackSigningUpUserPayload,
  UpdateLeasebackSigningUpUserResult,
  isAuthenticatedRequestParams,
  sellerLineLoginParams,
  signUpViaGeneralAdLpPayLoad,
  signUpViaGmoAlLpPayLoad,
  signUpViaInquiryChatFormPayLoad,
} from '@hooks_common/useAuth';

export type sellerServices = 'leaseback' | 'selling';

type AuthContext = {
  sellerLogin: (formData: FormData, uuid?: string) => Promise<boolean>;
  sellerLineLogin: (
    requestParams: sellerLineLoginParams,
    //ログイン後のsidとuser_idの紐付けリクエストに使う
    uuid?: string,
  ) => Promise<{
    user_type: 'new' | 'existing' | 'invalid';
    token?: { 'access-token': string; client: string; uid: string };
  }>;
  tokenValidity: TokenValidity;
  setTokenValidity: Dispatch<SetStateAction<TokenValidity>>;
  updateLeasebackSigningUpUser: (
    payload: UpdateLeasebackSigningUpUserPayload,
  ) => Promise<UpdateLeasebackSigningUpUserResult>;
  validateToken: (params: isAuthenticatedRequestParams) => Promise<boolean>;
  registerCityLpSeller: (payload: RegisterCityLpSellerPayload) => Promise<RegistreLpSellerResponse>;
  registerAlLpSeller: (payload: RegisterAlLpSellerPayload) => Promise<RegistreLpSellerResponse>;
  signUpViaGeneralAdLp: (payload: signUpViaGeneralAdLpPayLoad) => Promise<any>;
  signUpViaInquiryChatForm: (payload: signUpViaInquiryChatFormPayLoad) => Promise<{ id: number } | void>;
  signUpViaGmoAlLp: (payload: signUpViaGmoAlLpPayLoad) => Promise<RegistreLpSellerResponse>;
  logout: (userType: string) => Promise<void>;
  login_type?: 'line' | 'email';
  checkSellerLoginType: () => 'line' | 'email';
  sellerServices?: sellerServices[];
};

export const authContext = createContext<AuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(authContext);
  if (context === undefined) {
    throw Error('authContext is undefined');
  }
  return context;
};
