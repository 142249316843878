export const ISRRevalidateTime = 604800 as const;
export const MonthInputFormat = 'yyyy-MM';
export const DateInputFormat = 'yyyy-MM-dd';
export const MonthOutputFormat = 'yyyy年M月';
export const yaerOutputFormat = 'yyyy年';
export const DateOutputFormat = 'yyyy年M月d日';
export const DateOutputFormatWithSlash = 'yyyy/MM/dd';
export const DateOutputFormatWithColon = 'yyyy.MM.dd';
export const DateTimeOutputFormat = 'yyyy年MM月dd日HH時mm分';
export const ChatDateTimeOutputFormatWithSlash = 'MM/dd HH:mm';
export const DeskTopMediaQuery = '(min-width:576px)';
export const companyDetailPagePathRegex = /\/companies\/(\d{1,5})/;
export const companiesPagePathRegex = /companies/;
export const companyIDRegex = /\/companies\/(\d{1,5})/;

export const NAVIGATION_COMPANIES = [
  [
    {
      text: '地元で売却に強い不動産会社',
      link: '/companies',
    },
    {
      text: '土地の売却に強い不動産会社',
      link: '/companies/land',
    },
    {
      text: '一戸建ての売却に強い不動産会社',
      link: '/companies/house',
    },
    {
      text: 'マンションの売却に強い不動産会社',
      link: '/companies/mansion',
    },
  ],
  [
    {
      text: '高く売るのが得意な不動産会社',
      link: '/companies/sell_high',
    },
    {
      text: '早く売るのが得意な不動産会社',
      link: '/companies/sell_fast',
    },
    {
      text: 'どんな物件も断らない不動産会社',
      link: '/companies/any_properties',
    },
    {
      text: '買取が得意な不動産会社',
      link: '/companies/purchase',
    },
  ],
  [
    {
      text: '離婚による売却が得意な不動産会社',
      link: '/companies/divorce',
    },
    {
      text: '相続による売却が得意な不動産会社',
      link: '/companies/inheritance',
    },
    {
      text: 'リースバックに対応できる不動産会社',
      link: '/companies/leaseback',
    },
  ],
] as const;

export const NAVIGATION_MEDIA = {
  outi: {
    kaikae: {
      text: '買い替え・住み替え',
      url: 'https://iqrafudosan.com/smpf/2layer-kaikae-16363/',
    },
    rikon: {
      text: '離婚',
      url: 'https://iqrafudosan.com/smpf/2layer-rikon-15924/',
    },
    souzoku: {
      text: '相続',
      url: 'https://iqrafudosan.com/smpf/2layer-souzoku-15969/',
    },
    leaseback: {
      text: 'リースバック',
      url: 'https://iqrafudosan.com/smpf/lease-back/',
    },
    ziko: {
      text: '事故物件',
      url: 'https://iqrafudosan.com/smpf/2layer-zikobukken-16365/',
    },
    syakkin: {
      text: '金銭的理由（借金など）',
      url: 'https://iqrafudosan.com/smpf/2layer-syakkin-16354/',
    },
    loan: {
      text: '住宅ローン',
      url: 'https://iqrafudosan.com/smpf/2layer-loanbaikyaku-16245/',
    },
  },
  kiso: {
    matome: {
      text: '売却基礎知識まとめ',
      url: 'https://iqrafudosan.com/smpf/2layer-kisochisiki-16031/',
    },
    nagare: {
      text: '売却の流れ',
      url: 'https://iqrafudosan.com/smpf/2layer-kikan-16060/',
    },
    syurui: {
      text: '売却方法の種類',
      url: 'https://iqrafudosan.com/smpf/2layer-kisochisiki-16031/#i',
    },
    baikai: {
      text: '媒介契約とは',
      url: 'https://iqrafudosan.com/smpf/2layer-kisochisiki-16031/#i-13',
    },
    sateigaku: {
      text: '査定額について',
      url: 'https://iqrafudosan.com/smpf/2layer-kisochisiki-16031/#i-6',
    },
    erabikata: {
      text: '不動産会社の選び方',
      url: 'https://iqrafudosan.com/smpf/2layer-kikan-16060/#i-8',
    },
    zeikin: {
      text: '売却にかかる費用と税金',
      url: 'https://iqrafudosan.com/smpf/2layer-kikan-16060/#i-17',
    },
  },
} as const;

export const NotificationText = {
  error: 'エラーが発生しました',
  inaccessiblePage: 'このページはアクセスできません',
  loginFailed: 'メールアドレスまたはパスワードが間違っています',
  invalidValue: '不正な値が入力されています',
  unfilledRequiredItems: '必須項目が入力されていません',
  successCreateAdminUser: '運営ユーザーを作成しました',
  successEditAdminUser: '運営ユーザーを編集しました',
  successDeleteAdminUser: '運営ユーザーを削除しました',
  outsourcerCodeRequired: '外注者権限を1つ以上選択してください',
  successCreateAnnouncement: 'お知らせを作成しました',
  successEditAnnouncement: 'お知らせを編集しました',
  successDeleteAnnouncement: 'お知らせを削除しました',
  successCreateCompanyUser: '不動産会社ユーザーを作成しました',
  successEditCompanyUser: '不動産会社ユーザーを編集しました',
  successDeleteCompanyUser: '不動産会社ユーザーを削除しました',
  successCreateCity: '市区町村を作成しました',
  successEditCity: '市区町村を編集しました',
  successDeleteCity: '市区町村を削除しました',
  successCreateTown: '町域を作成しました',
  successEditTown: '町域を編集しました',
  successDeleteTown: '町域を削除しました',
  successCreateMansion: 'マンションを作成しました',
  successEditMansion: 'マンションを編集しました',
  successDeleteMansion: 'マンションを削除しました',
  successRequestMansion: 'マンション作成を依頼しました',
  successCreateCompanyNotificationEmails: '通知先を作成しました',
  successDeleteCompanyNotificationEmails: '通知先を削除しました',
  successCreateCompany: '会社を作成しました',
  successEditCompany: '会社を編集しました',
  successDeleteCompany: '会社を削除しました',
  successUpdateStep: 'ステップを更新しました',
  successUpdateCompanyStrongPoint: '強みを更新しました',
  successUpdateHandlePublic: '公開状態を更新しました',
  successEditAccount: 'アカウントを編集しました',
  successEditSaleCase: '売却実績を編集しました',
  successDraftSaveSaleCase: '売却実績を一時保存しました',
  successSaveSaleCase: '売却実績を申請しました',
  companyNotSelected: '会社が選択されていません',
  successUpdateSaleCase: '売却実績の状態を更新しました',
  successRejectSaleCase: '売却実績を差し戻しました',
  successDeleteSaleCase: '売却実績を削除しました',
  checkAgreementRequired: '【注意事項】 にチェックをして下さい',
  draftSaleCaseRequiredTown: '一時保存するには町域を入力して下さい',
  successCreateAgreementReport: '成約報告を登録しました',
  successEditAgreementReport: '成約報告を編集しました',
  successDeleteAgreementReport: '成約報告を削除しました',
  successUpdateCompanyInformation: '会社情報を編集しました',
  successUpdateCompanyProfile: '会社紹介文を編集しました',
  successApproveCompanyProfile: '会社紹介文を承認しました',
  successRejectedCompanyProfile: '会社紹介文を差し戻しました',
  successUpdateMainProfileImage: 'メイン写真を変更しました',
  successRequestAddImage: '写真の追加を依頼しました',
  successAddImage: '写真を追加しました',
  successApproveImage: '写真を承認しました',
  successRejectImage: '写真を差し戻しました',
  successDeleteProfileImage: '写真を削除しました',
  successUpdateCompanyTags: 'タグ情報を保存しました',
  successActivateIqloud: 'イクラウドに加盟しました',
  successCreateFreeMap: '無料マップを発行しました',
  successSubmitEmail: 'メールを送信しました',
  emailNotFound: 'このメールアドレスは登録されていません',
  resetPassword: 'パスワードを再設定しました',
  tokenExpired: 'こちらのURLは期限が切れています。',
};

export const ValidationText = {
  required: '入力して下さい',
  requeuedSelect: '選択して下さい',
  invalidZipCode: '郵便番号のフォーマットが違います',
  invalidEmail: '正しいメールアドレスを入力して下さい',
  takenEmail: 'このメールアドレスは使用されています',
  mismatchedPassword: 'パスワードが一致しません',
  invalidIntegerNumber: '半角で整数を入力して下さい',
  invalidPositiveIntegerNumber: '半角で0以上の整数を入力して下さい',
  invalidNumber: '半角数字を入力して下さい',
  invalidPriceFormat10k: '万単位で入力して下さい',
  invalidFloatNumber: '半角数字を小数点以下4桁以内で入力して下さい',
  invalidFloorPlan: '間取りの入力フォーマットが違います',
  invalidTelPhoneNumber: '無効な電話番号です',
  passwordMinLength: 'パスワードは8文字以上を入力して下さい。',
  townRequired: '町域まで選択して下さい',
  positiveInteger: '1以上の整数を入力して下さい',
};

export const CompanyStep = {
  created: '作成中',
  preparing: '準備中',
  inputting: '入力中',
  completed: '登録完了',
};

export const AgreementReportFileType = {
  sales_agreement: '売買契約書',
  commission_receipt: '仲介手数料領収書または仲介手数料の支払約定書',
  mediation_agreement: '媒介契約書',
};

export const MansionStatus = {
  available: '公開',
  closed: '非公開',
  requested: '確認依頼',
};

export const BuildingStructures = {
  wooden: '木造',
  light_steel: '軽量鉄骨造',
  steel: '(重量)鉄骨造',
  rc: '鉄筋コンクリート',
  src: '鉄骨鉄筋コンクリート',
};

export type BuildingStructuresKeys = keyof typeof BuildingStructures;

export const BuildingCompany = {
  house_maker: 'ハウスメーカー',
  not_house_maker: 'ハウスメーカー以外',
};

//TODO:iqloudにもあるので対応相談
export const LandRight = {
  ownership: '所有権',
  leasehold: '借地権',
  other_land_rights: 'その他',
};

//TODO:iqloudにもあるので対応相談
export const DealType = {
  mediation: '仲介',
  purchase: '買取',
};
//TODO:iqloudにもあるので対応相談
export const PropertyType = {
  mansion: 'マンション',
  land: '土地',
  used_house: '中古戸建て',
  new_house: '新築戸建て',
};

export const ResidenceCurrentStatus = {
  residence: '居住中',
  rented: '賃貸中',
  vacant: '空室',
};

export type ResidenceCurrentStatusKeys = keyof typeof ResidenceCurrentStatus;

export const LandCurrentStatus = {
  vacant: '更地',
  with_old_house: '古家あり',
  other_current_statuses: 'その他',
};

export type LandCurrentStatusKeys = keyof typeof LandCurrentStatus;

export const MediateType = {
  selling: '売り手',
  buying: '買い手',
  buying_and_selling: '売買両手',
  lending: '貸し手',
  borrowing: '借り手',
  lending_and_borrowing: '貸借両手',
};

//1のときだけ選択肢に1Rあり
export const FloorPlan = {
  option1: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  option2: ['K', 'DK', 'LDK', 'SLDK'],
  option3: ['R', 'K', 'DK', 'LDK', 'SLDK'],
};

export const OccupiedAreaType = {
  wall_core: '壁芯',
  inside: '内法',
};

export const PulldownPropertyType = {
  '': '全て',
  mansion: 'マンション',
  land: '土地',
  used_house: '中古戸建て',
  new_house: '新築戸建て',
};

export const SaleCasesStatusType = {
  draft: '一時保存',
  unapproved: '未承認',
  approved: '承認済み',
  rejected: '差し戻し',
};

export const PulldownSaleCasesStatusType = {
  '': '全て',
  draft: '一時保存',
  unapproved: '未承認',
  approved: '承認済み',
  rejected: '差し戻し',
};

export const ProfileImageStatusType = {
  unapproved: '未承認',
  approved: '承認済み',
  rejected: '差し戻し',
};

export const ManagementType = {
  consignment: '委託',
  self_management: '自主管理',
  unknown: '不明',
};

export const BillingStatus = {
  unclaimed: '未請求',
  billed: '請求済み',
  paid: '支払い済み',
};

export const DashboardSaleCaseFinalSaveRequiredFormKeys = {
  mansion: [
    'mansion_id',
    'contract_date',
    'price',
    'occupied_area',
    'floor',
    'room_number',
    'floor_plan',
    'current_status',
  ],
  land: [
    'town_code',
    'address',
    'contract_date',
    'price',
    'current_status',
    'land_area',
    'land_area_detail',
    'architectural_condition',
  ],
  used_house: [
    'town_code',
    'address',
    'contract_date',
    'price',
    'floor',
    'floor_plan',
    'current_status',
    'land_area',
    'land_area_detail',
    'building_area',
    'building_structure',
    'land_right',
    'home_inspection',
  ],
  new_house: [
    'town_code',
    'address',
    'contract_date',
    'price',
    'floor',
    'floor_plan',
    'land_area',
    'land_area_detail',
    'building_area',
    'building_structure',
    'land_right',
  ],
};

export const DashboardSaleCaseValidationLabels: { [key: string]: string } = {
  company_id: '会社ID',
  contract_date: '売買契約締結日',
  price: '売買価格',
  sale_reasons: '売却理由',
  information_map: 'マイソク',
  town: '町域',
  town_code: '町域',
  address: '町域以下',
  mansion_id: 'マンション',
  building_structure: '建物構造',
  built_date: '建築年月',
  mediation_contract_date: '媒介契約締結日',
  floor: '階数',
  occupied_area: '専有面積',
  current_status: '現況・状態',
  room_number: '部屋番号',
  floor_plan: '間取り',
  building_area: '建物面積',
  rejected_reason: '差し戻し理由',
  land_right: '土地権利',
  land_area: '土地面積',
};

export const ValidationLabels = [
  {
    formName: 'code',
    label: 'コード',
  },
  {
    formName: 'company_id',
    label: '会社ID',
  },
  {
    formName: 'status',
    label: 'ステータス',
  },
  {
    formName: 'email',
    label: 'メールアドレス',
  },
  {
    formName: 'zipcode',
    label: '郵便番号',
  },
  {
    formName: 'town_code',
    label: '町域コード',
  },
  {
    formName: 'address',
    label: '住所',
  },
  {
    formName: 'nearest_station',
    label: '最寄駅',
  },
  {
    formName: 'management_company',
    label: '管理会社',
  },
  {
    formName: 'management_type',
    label: '管理形態',
  },
  {
    formName: 'use_district',
    label: '用途地域',
  },
  {
    formName: 'building_structure',
    label: '建物構造',
  },
  {
    formName: 'built_date',
    label: '建築年月',
  },
  {
    formName: 'ground_floors',
    label: '地上階数',
  },
  {
    formName: 'underground_floors',
    label: '地下階数',
  },
  {
    formName: 'total_units',
    label: '総戸数',
  },
  {
    formName: 'former_for_sale_owner',
    label: '旧分譲主',
  },
  {
    formName: 'construction_company',
    label: '施工会社',
  },
  {
    formName: 'price',
    label: '売買価格',
  },
  {
    formName: 'contract_date',
    label: '売買契約締結日',
  },
  {
    formName: 'mediation_contract_date',
    label: '媒介契約締結日',
  },
  {
    formName: 'sale_reasons',
    label: '売却理由',
  },
  {
    formName: 'floor',
    label: '階数',
  },
  {
    formName: 'occupied_area',
    label: '専有面積',
  },
  {
    formName: 'mansion_id',
    label: 'マンション',
  },
  {
    formName: 'current_status',
    label: '現況・状態',
  },
  {
    formName: 'room_number',
    label: '部屋番号',
  },
  {
    formName: 'floor_plan',
    label: '間取り',
  },
  {
    formName: 'building_area',
    label: '建物面積',
  },
  {
    formName: 'rejected_reason',
    label: '差し戻し理由',
  },
  {
    formName: 'land_right',
    label: '土地権利',
  },
  {
    formName: 'land_area',
    label: '土地面積',
  },
  {
    formName: 'tel',
    label: '電話番号',
  },
  {
    formName: 'fax',
    label: 'FAX番号',
  },
  {
    formName: 'lat',
    label: '緯度',
  },
  {
    formName: 'long',
    label: '経度',
  },
  {
    formName: 'top_title',
    label: 'アピールポイント',
  },
  {
    formName: 'title_first',
    label: '会社紹介タイトル１',
  },
  {
    formName: 'body_first',
    label: '会社紹介文１',
  },
];

export const Prefectures: { [key: number]: string } = {
  1: '北海道',
  2: '青森県',
  3: '岩手県',
  4: '宮城県',
  5: '秋田県',
  6: '山形県',
  7: '福島県',
  8: '茨城県',
  9: '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈川県',
  15: '新潟県',
  16: '富山県',
  17: '石川県',
  18: '福井県',
  19: '山梨県',
  20: '長野県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌山県',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児島県',
  47: '沖縄県',
};

export const PrefecturesWithAll: { [key: number]: string } = {
  0: '全て',
  1: '北海道',
  2: '青森県',
  3: '岩手県',
  4: '宮城県',
  5: '秋田県',
  6: '山形県',
  7: '福島県',
  8: '茨城県',
  9: '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈川県',
  15: '新潟県',
  16: '富山県',
  17: '石川県',
  18: '福井県',
  19: '山梨県',
  20: '長野県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌山県',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児島県',
  47: '沖縄県',
};

export const PrefecturesForLicense: { [key: number]: string } = {
  1: '北海道知事免許',
  2: '青森県知事免許',
  3: '岩手県知事免許',
  4: '宮城県知事免許',
  5: '秋田県知事免許',
  6: '山形県知事免許',
  7: '福島県知事免許',
  8: '茨城県知事免許',
  9: '栃木県知事免許',
  10: '群馬県知事免許',
  11: '埼玉県知事免許',
  12: '千葉県知事免許',
  13: '東京都知事免許',
  14: '神奈川県知事免許',
  15: '新潟県知事免許',
  16: '富山県知事免許',
  17: '石川県知事免許',
  18: '福井県知事免許',
  19: '山梨県知事免許',
  20: '長野県知事免許',
  21: '岐阜県知事免許',
  22: '静岡県知事免許',
  23: '愛知県知事免許',
  24: '三重県知事免許',
  25: '滋賀県知事免許',
  26: '京都府知事免許',
  27: '大阪府知事免許',
  28: '兵庫県知事免許',
  29: '奈良県知事免許',
  30: '和歌山県知事免許',
  31: '鳥取県知事免許',
  32: '島根県知事免許',
  33: '岡山県知事免許',
  34: '広島県知事免許',
  35: '山口県知事免許',
  36: '徳島県知事免許',
  37: '香川県知事免許',
  38: '愛媛県知事免許',
  39: '高知県知事免許',
  40: '福岡県知事免許',
  41: '佐賀県知事免許',
  42: '長崎県知事免許',
  43: '熊本県知事免許',
  44: '大分県知事免許',
  45: '宮崎県知事免許',
  46: '鹿児島県知事免許',
  47: '沖縄県知事免許',
  99: '国土交通大臣免許',
};

//チャット売却物件種別
export const ChatPropertyType = {
  mansion: 'マンション一室',
  land: '土地',
  used_house: '戸建て',
  terrace_house: 'テラスハウス',
  commercial: '店舗・事務所・倉庫',
  mansion_building: 'マンション一棟',
  apartment_building: 'アパート一棟',
  office_building: 'ビル一棟',
};

export type ChatPropertyKeys = keyof typeof ChatPropertyType;

export const PullDownChatPropertyType = {
  '': '全て',
  mansion: 'マンション一室',
  land: '土地',
  used_house: '戸建て',
  terrace_house: 'テラスハウス',
  commercial: '店舗・事務所・倉庫',
  mansion_building: 'マンション一棟',
  apartment_building: 'アパート一棟',
  office_building: 'ビル一棟',
};

export const ChatValidationText = {
  required: 'こちらの入力は必須です',
  invalid: '不正な値が入力されています',
  need_property: 'こちらの入力は必須です',
  invalidIntegerNumber: '半角数字を入力して下さい',
  invalidFloatNumber: '半角数字を小数点以下4桁以内で入力して下さい',
  addressLimit: '230文字以内で入力して下さい',
  mansionNameLimit: '80文字以内で入力して下さい',
  roomNumberLimit: '40文字以内で入力して下さい',
  noteLimit: '450文字以内で入力して下さい',
  reformLimit: '1000文字以内で入力して下さい',
};

export const ChatFloorPlanSelectList = {
  '1R(ワンルーム)': '1R(ワンルーム)',
  '1K/1DK': '1K/1DK',
  '1LK/1LDK': '1LK/1LDK',
  '2K/2DK': '2K/2DK',
  '2LK/2LDK': '2LK/2LDK',
  '3K/3DK': '3K/3DK',
  '3LK/3LDK': '3LK/3LDK',
  '4K/4DK': '4K/4DK',
  '4LK/4LDK': '4LK/4LDK',
  '5K/5DK': '5K/5DK',
  '5LK/5LDK': '5LK/5LDK',
  '6K/6DK': '6K/6DK',
  '6LK/6LDK': '6LK/6LDK',
  '6LK/6LDK以上': '6LK/6LDK以上',
  other: 'その他',
  unknown: '不明',
};

//チャットステータス
export const ChatRoomStatus = {
  before_start: 'チャット開始前',
  started: 'チャット開始済',
  completed_assessment: '訪問査定済',
  made_mediation_contract: '媒介契約済',
  made_contract: '売買契約済',
  completed_deal: '売却完了',
  pending: '保留中',
  cancelled: '売却完了',
};

export const PullDownChatRoomStatus = {
  '': '全て',
  before_start: 'チャット開始前',
  started: 'チャット開始済',
  completed_assessment: '訪問査定済',
  made_mediation_contract: '媒介契約済',
  made_contract: '売買契約済',
  completed_deal: '売却完了',
  pending: '保留中',
  cancelled: '売却完了',
};

//チャットのトークルームの種類
//送信者_受信者
export type ChatRoomType = 'seller_company' | 'seller_admin' | 'seller_financial_agency' | 'seller_company_contact';

//登録物件項目が必要な物件種別

export type PropertyTypeList = Array<keyof typeof ChatPropertyType>;

export const allPropertyTypes: PropertyTypeList = [
  'mansion',
  'land',
  'used_house',
  'terrace_house',
  'commercial',
  'mansion_building',
  'apartment_building',
  'office_building',
];

//step2
export const mansionNamePropertyTypeList: PropertyTypeList = [
  'mansion',
  'mansion_building',
  'apartment_building',
  'office_building',
];

export const floorPropertyTypeList: PropertyTypeList = [
  'mansion',
  'mansion_building',
  'apartment_building',
  'office_building',
];

export const totalUnitsPropertyTypeList: PropertyTypeList = ['mansion_building', 'apartment_building'];

export const roomNumberPropertyTypeList: PropertyTypeList = ['mansion'];

export const occupiedAreaPropertyTypeList: PropertyTypeList = ['mansion'];

export const floorPlanPropertyTypeList: PropertyTypeList = ['mansion', 'used_house', 'terrace_house', 'commercial'];

export const builtYearPropertyTypeList: PropertyTypeList = [
  'mansion',
  'used_house',
  'terrace_house',
  'commercial',
  'mansion_building',
  'apartment_building',
  'office_building',
];

export const reformPropertyTypeList: PropertyTypeList = [
  'mansion',
  'used_house',
  'terrace_house',
  'commercial',
  'mansion_building',
  'apartment_building',
  'office_building',
];

export const landAreaPropertyTypeList: PropertyTypeList = [
  'land',
  'used_house',
  'terrace_house',
  'commercial',
  'mansion_building',
  'apartment_building',
  'office_building',
];

export const buildingAreaPropertyTypeList: PropertyTypeList = [
  'used_house',
  'terrace_house',
  'commercial',
  'mansion_building',
  'apartment_building',
  'office_building',
];

//step3
export const landCurrentStatusPropertyTypeList: PropertyTypeList = ['land'];

export const buildingCurrentStatusPropertyTypeList: PropertyTypeList = [
  'mansion',
  'used_house',
  'terrace_house',
  'commercial',
  'mansion_building',
  'apartment_building',
  'office_building',
];

export const loanBalancePropertyTypeList: PropertyTypeList = allPropertyTypes;

export const ownerShipPropertyTypeList: PropertyTypeList = allPropertyTypes;

export const saleReasonsPropertyTypeList: PropertyTypeList = allPropertyTypes;

export const preferredTimingPropertyTypeList: PropertyTypeList = allPropertyTypes;

export const inConsultationPropertyTypeList: PropertyTypeList = allPropertyTypes;

// chat-profile-gender
export const ChatProfileGender = {
  male: '男性',
  female: '女性',
  other_genders: 'その他',
};

//売却希望時期
export const PreferredTiming = {
  immediately: 'すぐに現金化したい',
  within_3_months: '3ヶ月以内',
  within_6_months: '半年以内',
  within_a_year: '1年以内',
  more_than_a_year: '1年以上先',
  nothing: '売却する意思はない',
};

export type PreferredTimingKeys = keyof typeof PreferredTiming;

//物件との関係
export const OwnerShip = {
  owner: '物件の名義人本人',
  family: '名義人の家族／親族',
  co_owner: '共有名義',
  agent: '物件所有者の了承を得た代理人',
};

export type OwnerShipKeys = keyof typeof OwnerShip;

//会社検索用propertyType
export const SearchCompaniesKeyPropertyType = {
  sale_case_count: '全て',
  sale_case_land: '土地',
  sale_case_mansion: 'マンション',
  sale_case_house: '一戸建て',
};

//会社検索用query
export const SearchCompanySortQueries = {
  sale_case_mediation: '地元の売却に強い',
  sale_case_mansion: 'マンションの売却に強い',
  sale_case_land: '土地の売却に強い',
  sale_case_house: '一戸建ての売却に強い',
  sale_case_land_house: '土地戸建の売却に強い',
  sale_case_purchase: '買取が得意な',
  sell_high: '高く売るのが得意な',
  sell_fast: '早く売るのが得意な',
  not_decline: 'どんな物件も断らない',
  divorce: '離婚による売却が得意な',
  inheritance: '相続による売却が得意な',
  leaseback: 'リースバックに対応できる',
} as const;

//順番はデザイン通り
export const RegionDataList = {
  1: {
    name: '北海道・東北',
    prefectures: [1, 2, 3, 5, 4, 6, 7],
  },
  2: {
    name: '関東',
    prefectures: [13, 14, 11, 12, 8, 9, 10],
  },
  3: {
    name: '東海・甲信越・北陸',
    prefectures: [23, 21, 22, 24, 19, 20, 15, 16, 17, 18],
  },
  4: {
    name: '近畿',
    prefectures: [27, 28, 26, 25, 29, 30],
  },
  5: {
    name: '中国・四国',
    prefectures: [33, 34, 31, 32, 35, 37, 36, 38, 39],
  },
  6: {
    name: '九州・沖縄',
    prefectures: [40, 41, 42, 44, 43, 45, 46, 47],
  },
};

export const PrefecturesListWithoutSuffix: { [key: number]: string } = {
  1: '北海道',
  2: '青森',
  3: '岩手',
  4: '宮城',
  5: '秋田',
  6: '山形',
  7: '福島',
  8: '茨城',
  9: '栃木',
  10: '群馬',
  11: '埼玉',
  12: '千葉',
  13: '東京',
  14: '神奈川',
  15: '新潟',
  16: '富山',
  17: '石川',
  18: '福井',
  19: '山梨',
  20: '長野',
  21: '岐阜',
  22: '静岡',
  23: '愛知',
  24: '三重',
  25: '滋賀',
  26: '京都',
  27: '大阪',
  28: '兵庫',
  29: '奈良',
  30: '和歌山',
  31: '鳥取',
  32: '島根',
  33: '岡山',
  34: '広島',
  35: '山口',
  36: '徳島',
  37: '香川',
  38: '愛媛',
  39: '高知',
  40: '福岡',
  41: '佐賀',
  42: '長崎',
  43: '熊本',
  44: '大分',
  45: '宮崎',
  46: '鹿児島',
  47: '沖縄',
};
export const PublicPrefecturesWithAll: { [key: number]: string } = {
  0: '全国',
  1: '北海道',
  2: '青森県',
  3: '岩手県',
  4: '宮城県',
  5: '秋田県',
  6: '山形県',
  7: '福島県',
  8: '茨城県',
  9: '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈川県',
  15: '新潟県',
  16: '富山県',
  17: '石川県',
  18: '福井県',
  19: '山梨県',
  20: '長野県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌山県',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児島県',
  47: '沖縄県',
};

export const PrefecturalOfficeCoordinate: { [key: number]: number[] } = {
  1: [43.06417, 141.34694],
  2: [40.82444, 140.74],
  3: [39.70361, 141.1525],
  4: [38.26889, 140.87194],
  5: [39.71861, 140.1025],
  6: [38.24056, 140.36333],
  7: [37.75, 140.46778],
  8: [36.34139, 140.44667],
  9: [36.56583, 139.88361],
  10: [36.39111, 139.06083],
  11: [35.85694, 139.64889],
  12: [35.60472, 140.12333],
  13: [35.68944, 139.69167],
  14: [35.44778, 139.6425],
  15: [37.90222, 139.02361],
  16: [36.69528, 137.21139],
  17: [36.59444, 136.62556],
  18: [36.06528, 136.22194],
  19: [35.66389, 138.56833],
  20: [36.65139, 138.18111],
  21: [35.39111, 136.72222],
  22: [34.97694, 138.38306],
  23: [35.18028, 136.90667],
  24: [34.73028, 136.50861],
  25: [35.00444, 135.86833],
  26: [35.02139, 135.75556],
  27: [34.68639, 135.52],
  28: [34.69139, 135.18306],
  29: [34.68528, 135.83278],
  30: [34.22611, 135.1675],
  31: [35.50361, 134.23833],
  32: [35.47222, 133.05056],
  33: [34.66167, 133.935],
  34: [34.39639, 132.45944],
  35: [34.18583, 131.47139],
  36: [34.06583, 134.55944],
  37: [34.34028, 134.04333],
  38: [33.84167, 132.76611],
  39: [33.55972, 133.53111],
  40: [33.60639, 130.41806],
  41: [33.24944, 130.29889],
  42: [32.74472, 129.87361],
  43: [32.78972, 130.74167],
  44: [33.23806, 131.6125],
  45: [31.91111, 131.42389],
  46: [31.56028, 130.55806],
  47: [26.2125, 127.68111],
};

export const SELLER_TEL_REGEX = /^0(\d{9,10}|\d{1,4}-\d{3,5}-\d{4})$/;

export const POSITIVE_INTEGER_REGEX = /^[1-9]\d*$/;

export const CompanyProfileDataKeyList = [
  {
    title: 'title_first',
    body: 'body_first',
  },
  {
    title: 'title_second',
    body: 'body_second',
  },
  {
    title: 'title_third',
    body: 'body_third',
  },
  {
    title: 'title_fourth',
    body: 'body_fourth',
  },
  {
    title: 'title_fifth',
    body: 'body_fifth',
  },
  {
    title: 'title_sixth',
    body: 'body_sixth',
  },
  {
    title: 'title_seventh',
    body: 'body_seventh',
  },
  {
    title: 'title_eighth',
    body: 'body_eighth',
  },
  {
    title: 'title_ninth',
    body: 'body_ninth',
  },
  {
    title: 'title_tenth',
    body: 'body_tenth',
  },
] as const;

export type CompanyProfileDataTitleKeyLiteralList = typeof CompanyProfileDataKeyList[number]['title'];

export type CompanyProfileDataBodyKeyLiteralList = typeof CompanyProfileDataKeyList[number]['body'];

export const PublicPropertyType = {
  mansion: '中古マンション',
  land: '土地',
  house: '中古一戸建て',
};

export const PublicTrendType = {
  flat: '横ばい',
  up: '上昇',
  strong_up: '急上昇',
  down: '下落',
  strong_down: '急下落',
};

export const PublicAreaTypes = {
  country: '全国',
  prefecture: '都道府県',
  city: '市区町村',
};

export type SalesStatCsvType = keyof typeof SalesStatCsvTypeText;

export const SalesStatCsvTypeText = {
  sales_stat: 'sales_stat',
  real_estate_market: 'real_estate_market',
  real_estate_market_detail: 'real_estate_market_detail',
  property_feature: 'property_feature',
  sales_stat_survey: 'sales_stat_survey',
};

export const LandTaxAssessmentMapUrl = 'https://www.rosenka.nta.go.jp/';

//key名がURLのParameterになる
export const CompaniesPageCategory = {
  default: '売却に強くて実績が豊富な',
  land: '土地の売却に強い',
  house: '一戸建ての売却に強い',
  mansion: 'マンションの売却に強い',
  sell_high: '高く売るのが得意な',
  sell_fast: '早く売るのが得意な',
  any_properties: 'どんな物件も断らない',
  purchase: '買取が得意な',
  divorce: '離婚による売却が得意な',
  inheritance: '相続による売却が得意な',
  leaseback: 'リースバックに対応できる',
} as const;

//title, h1, パンクズに都道府県名を表示するかのフラグ
export const showPrefectureNameInTitleFlag = true;

export const saleCaseTypeText: { [key in keyof typeof SearchCompanySortQueries]: string } = {
  sale_case_mediation: '売却実績数',
  sale_case_purchase: '買取実績数',
  sale_case_land: '土地売却実績',
  sale_case_house: '戸建て売却実績',
  sale_case_mansion: 'マンション売却実績',
  //以下全て'売却実績数'
  sale_case_land_house: '売却実績数',
  sell_high: '売却実績数',
  sell_fast: '売却実績数',
  not_decline: '売却実績数',
  divorce: '売却実績数',
  inheritance: '売却実績数',
  leaseback: '売却実績数',
};

const generateOccupiedAreaMap = (): Record<string, string> => {
  const result: Record<string, string> = {};

  // 初期キーを10に設定
  let key = 10;

  while (key <= 500) {
    const tsuboRaw = key / 3.30578;
    const tsuboRounded = Math.ceil(tsuboRaw * 100) / 100;
    const tsuboDisplay = Math.round(tsuboRounded * 10) / 10;

    // バリューのフォーマット
    const value = `${key}㎡（${tsuboDisplay.toFixed(1)}坪）以上`;

    // 結果に追加
    result[key.toString()] = value;

    // キーの更新
    if (key < 40) {
      key += 5;
    } else {
      key += 10;
    }
  }

  return result;
};

export const OccupiedAreaMap = generateOccupiedAreaMap();
