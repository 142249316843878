import { useState } from 'react';

import { useRouter } from 'next/router';

import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

import { VIRTUAL_CHAT_DISCOVERY_SOURCE, VIRTUAL_CHAT_GENDER } from '@components_iqfu/virtual_chat_form/formAtom';

import { ChatPropertyKeys, PreferredTimingKeys } from '@constants_iqfu';

const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

//cookieの期限 デフォルト180日にセット
const cookieExpires = 60 * 60 * 24 * 180;

export type TokenValidity = 'unvalidated' | 'invalid' | 'valid' | 'unvalidated';

export type UpdateLeasebackSigningUpUserPayload =
  | {
      code: string;
      tel: string;
      status: 'tel_confirmed';
    }
  | {
      code: string;
      tel: string;
      status: 'line_failed';
    }
  | {
      code: string;
      tel: string;
      status: 'completed';
      line_id: string;
      line_access_token: string;
      line_refresh_token: string;
    };

export type UpdateLeasebackSigningUpUserResult =
  | {
      success: boolean;
    }
  | {
      id: number;
    }
  | { code: 'expired' };

export type CommonLpPayLoad = {
  property_type: ChatPropertyKeys;
  town_code: string;
  address: string;
  email: string;
  first_name: string;
  last_name: string;
  tel: string;
  password: string;
  sid: string;
  building_area?: number;
  land_area?: number;
  mansion_name?: string;
  room_number?: string;
  loan_balance: number;
  preferred_timing: PreferredTimingKeys;
};

// RegisterCityLpSellerPayload 型
export type RegisterCityLpSellerPayload = CommonLpPayLoad & {
  occupied_area?: number;
  company_ids?: number[];
};

// RegisterAlLpSellerPayload 型
export type RegisterAlLpSellerPayload = CommonLpPayLoad;

// signUpViaGmoAlLpPayLoad 型
export type signUpViaGmoAlLpPayLoad = CommonLpPayLoad & {
  company_ids?: number[];
};

export type signUpViaGeneralAdLpPayLoad = {
  email: string;
  first_name: string;
  last_name: string;
  tel: string;
  password: string;
  sid: string;
  inquiry_purposes: string[];
  referer_event: 'inquiry_from_general_ad_lp' | 'inquiry_from_cm_lp';
  discovery_source: keyof typeof VIRTUAL_CHAT_DISCOVERY_SOURCE | null;
  city_code: number;
};

export type signUpViaInquiryChatFormPayLoad = {
  email: string;
  first_name: string;
  last_name: string;
  gender: keyof typeof VIRTUAL_CHAT_GENDER;
  age: string;
  tel: string;
  password: string;
  sid: string;
  inquiry_purposes: string[];
  discovery_source: keyof typeof VIRTUAL_CHAT_DISCOVERY_SOURCE | null;
};

export type RegistreLpSellerResponse =
  | {
      success: true;
      id: number;
    }
  | {
      success: false;
    };

export type isAuthenticatedRequestParams = { page_path: string; sid: string };

export type sellerLineLoginParams = { access_token: string; refresh_token: string; line_id: string };

export type sellerLoginResponse = {
  data: {
    id: number;
    email: string;
    login_type: 'email' | 'line';
    referer_event: 'selling_consultation_with_company' | 'selling_consultation_without_company' | 'inquiry_to_iqra';
    services: ('seller' | 'leaseback')[];
  };
};

export type sellerLineLoginResponse = {
  line_id: string;
  line_access_token: string;
  line_refresh_token: string;
  services: ('seller' | 'leaseback')[];
  user_type: 'new' | 'existing';
};

export type ValidateTokenRequestResponse = {
  success: boolean;
  data: {
    id: number;
    first_name: string;
    first_name_kana: string;
    last_name: string;
    last_name_kana: string;
    uid: string;
    tel: string;
    email: string;
    notification_email: string;
    town_id: number;
    v1_id: number | null;
    zipcode: string;
    address: string;
    age: number;
    allow_password_change: boolean;
    discarded_at: string; // ISO 8601 datetime format
    provider: string;
    gender: string;
    user_type: string;
    services: ('seller' | 'leaseback')[];
  };
};

export const useProvideAuth = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'expiry',
    'access-token',
    'client',
    'uid',
    'services',
    'user-type',
    'admin-user-role',
    'user-data',
    'login_type',
  ]);
  const router = useRouter();

  const [tokenValidity, setTokenValidity] = useState<TokenValidity>('unvalidated');

  const resetLoginState = () => {
    removeCookie('expiry', { path: '/' });
    removeCookie('access-token', { path: '/' });
    removeCookie('client', { path: '/' });
    removeCookie('user-type', { path: '/' });
    removeCookie('uid', { path: '/' });
    removeCookie('admin-user-role', { path: '/' });
    removeCookie('user-data', { path: '/' });
    localStorage.removeItem('user-data-local');
    removeCookie('services', { path: '/' });
    removeCookie('login_type', { path: '/' });
    setTokenValidity('unvalidated');
  };

  const setUserUuidToUserId = async (
    uuid: string,
    requestHeader: {
      uid: string;
      client: string;
      'access-token': string;
    },
  ) => {
    const url = `${backendBasePath}/seller/favorite_companies/set_key`;

    const submitData = JSON.stringify({ key: uuid });
    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        uid: requestHeader.uid,
        client: requestHeader.client,
        'access-token': requestHeader['access-token'],
        'Content-Type': 'application/json',
      },
      body: submitData,
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });
    return result;
  };

  const sellerLoginRequest = async (
    formData: FormData,
  ): Promise<{
    status: 'success' | 'error' | undefined;
    result: sellerLoginResponse;
    token: {
      'access-token': string;
      client: string;
      uid: string;
    };
  }> => {
    const url = `${backendBasePath}/seller/sign_in`;

    let status;
    let token = {
      'access-token': '',
      client: '',
      uid: '',
    };

    const result: sellerLoginResponse = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          status = 'success';
          setCookie('expiry', response.headers.get('expiry'), { maxAge: cookieExpires, path: '/' });
          setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
          setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
          setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
          setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
          setCookie('login_type', 'email', { maxAge: cookieExpires, path: '/' });

          token['access-token'] = response.headers.get('access-token')!;
          token.client = response.headers.get('client')!;
          token.uid = response.headers.get('uid')!;

          return response.json();
        } else {
          status = 'error';
          return response.json();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('Error:', error);
      });

    console.log(' status, result ', status, result);

    return { status, result, token };
  };

  const sellerLogin = async (formData: FormData, uuid?: string): Promise<boolean> => {
    return new Promise(async (resolve) => {
      resetLoginState();

      const { status, result, token } = await sellerLoginRequest(formData);

      const tokenInfo = {
        'access-token': token['access-token'],
        client: token.client,
        uid: token.uid,
      };

      if (!status) {
        router.push(`/service-unavailable`);
      } else if (status !== 'success') {
        resolve(false);
      } else {
        setCookie('services', result?.data?.services, { maxAge: cookieExpires, path: '/' });
        if (uuid) {
          await setUserUuidToUserId(uuid, tokenInfo);
        }

        setTokenValidity('valid');

        resolve(true);
      }
    });
  };

  const sellerLineLoginRequest = async (
    requestParams: sellerLineLoginParams,
  ): Promise<{
    status: 'success' | 'error' | undefined;
    result: sellerLineLoginResponse;
    token: {
      'access-token': string;
      client: string;
      uid: string;
    };
  }> => {
    const url = `${backendBasePath}/seller/line_sign_in`;

    const submitValue = JSON.stringify({
      line_id: requestParams.line_id,
      line_access_token: requestParams.access_token,
      line_refresh_token: requestParams.refresh_token,
    });

    console.log('submitValue', submitValue);
    let status;
    let token = {
      'access-token': '',
      client: '',
      uid: '',
    };
    const result: sellerLineLoginResponse = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: submitValue,
    })
      .then((response) => {
        if (response.status === 200) {
          status = 'success';
          setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
          setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
          setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
          setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
          setCookie('login_type', 'line', { maxAge: cookieExpires, path: '/' });

          token['access-token'] = response.headers.get('access-token')!;
          token.client = response.headers.get('client')!;
          token.uid = response.headers.get('uid')!;

          return response.json();
        } else {
          status = 'error';
          return response.json();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('Error:', error);
      });

    //if (status === 'success') setLoginUserId(result.id);

    return { status, result, token };
  };

  const sellerLineLogin = async (
    requestParams: sellerLineLoginParams,
    //ログイン後のsidとuser_idの紐付けリクエストに使う
    uuid?: string,
  ): Promise<{
    user_type: 'new' | 'existing' | 'invalid';
    token?: { 'access-token': string; client: string; uid: string };
  }> => {
    return new Promise(async (resolve) => {
      resetLoginState();

      const { status, result, token } = await sellerLineLoginRequest(requestParams);

      if (!status) {
        router.push(`/service-unavailable`);
      } else if (status !== 'success') {
        resolve({ user_type: 'invalid' });
      } else {
        setCookie('services', result?.services, { maxAge: cookieExpires, path: '/' });
        const tokenInfo = {
          'access-token': token['access-token'],
          client: token.client,
          uid: token.uid,
        };
        if (uuid) {
          await setUserUuidToUserId(uuid, tokenInfo);
        }

        setTokenValidity('valid');

        resolve({ user_type: result.user_type, token: token });
      }
    });
  };

  const validateToken = async (params: isAuthenticatedRequestParams): Promise<boolean> => {
    const validateTokenRequest = async (): Promise<ValidateTokenRequestResponse> => {
      const query = queryString.stringify(params);

      const url = `${backendBasePath}/seller/validate_token?${query}`;

      const result = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          uid: cookies['uid'],
          client: cookies['client'],
          'access-token': cookies['access-token'],
          'cache-control': 'no-cache',
        },
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error('Error:', error);
        });
      return result;
    };
    const result = await validateTokenRequest();

    if (result && result.success) {
      setTokenValidity('valid');
      //loginのときのものとレスポンスのデータの形が今は違う
      if (result?.data?.services) {
        setCookie('services', result?.data?.services, { maxAge: cookieExpires, path: '/' });
      }
    } else {
      setTokenValidity('invalid');
    }

    return !!result?.success;
  };

  const checkSellerLoginType = () => {
    return cookies['login_type'];
  };

  const logout = async (userType: string) => {
    const url = `${backendBasePath}/${userType}/sign_out`;
    const logoutRequest = async (): Promise<any> => {
      const result = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          uid: cookies['uid'],
          client: cookies['client'],
          'access-token': cookies['access-token'],
        },
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error('Error:', error);
        });
      return result;
    };
    (async () => {
      const result = await logoutRequest();
      if (!result.success) {
        console.error('logout request failed');
      }
      if (userType === 'seller') {
        router.push(`/`);
      } else {
        router.push(`/console/${userType}/login`);
      }
      resetLoginState();
    })();
  };

  const updateLeasebackSigningUpUser = async (
    payload: UpdateLeasebackSigningUpUserPayload,
  ): Promise<UpdateLeasebackSigningUpUserResult> => {
    const url = `${backendBasePath}/leaseback_signing_up_users/${payload.code}`;
    const isCompleteRequest = payload.status === 'completed';

    const result = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          if (isCompleteRequest) {
            resetLoginState();
            setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
            setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
            setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
            setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
            setCookie('login_type', 'line', { maxAge: cookieExpires, path: '/' });

            //しばらくはこの経路で登録するユーザーのサービスはleasebackのみ
            setCookie('services', ['leaseback'], { maxAge: cookieExpires, path: '/' });

            setTokenValidity('valid');
          }
          return response.json();
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        //Sentry.captureException(error);
        return error;
      });
    return result;
  };

  const registerCityLpSeller = async (payload: RegisterCityLpSellerPayload): Promise<RegistreLpSellerResponse> => {
    const url = `${backendBasePath}/ad_lp_seller_users`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          const cookieExpires = 60 * 60 * 24 * 180;
          setCookie('expiry', response.headers.get('expiry'), { maxAge: cookieExpires, path: '/' });
          setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
          setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
          setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
          setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
          setCookie('login_type', 'email', { maxAge: cookieExpires, path: '/' });

          return response.json();
        } else {
          return false;
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return false;
      });

    setTokenValidity('valid');

    if (result) {
      return {
        success: true,
        id: result.id,
      };
    } else {
      return {
        success: false,
      };
    }
  };

  const registerAlLpSeller = async (payload: signUpViaGmoAlLpPayLoad): Promise<RegistreLpSellerResponse> => {
    const url = `${backendBasePath}/alliance_lp/age_technologies/seller_users`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          const cookieExpires = 60 * 60 * 24 * 180;
          setCookie('expiry', response.headers.get('expiry'), { maxAge: cookieExpires, path: '/' });
          setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
          setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
          setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
          setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
          setCookie('login_type', 'email', { maxAge: cookieExpires, path: '/' });

          return response.json();
        } else {
          return false;
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return false;
      });

    if (result) {
      setTokenValidity('valid');
      return {
        success: true,
        id: result.id,
      };
    } else {
      setTokenValidity('invalid');
      return {
        success: false,
      };
    }
  };

  const signUpViaGmoAlLp = async (payload: signUpViaGmoAlLpPayLoad): Promise<RegistreLpSellerResponse> => {
    const url = `${backendBasePath}/affiliate_lp/gmo_media/seller_users`;

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          const cookieExpires = 60 * 60 * 24 * 180;
          setCookie('expiry', response.headers.get('expiry'), { maxAge: cookieExpires, path: '/' });
          setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
          setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
          setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
          setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
          setCookie('login_type', 'email', { maxAge: cookieExpires, path: '/' });

          return response.json();
        } else {
          return false;
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return false;
      });

    if (result) {
      setTokenValidity('valid');
      return {
        success: true,
        id: result.id,
      };
    } else {
      setTokenValidity('invalid');
      return {
        success: false,
      };
    }
  };

  const signUpViaGeneralAdLp = async (payloadParams: signUpViaGeneralAdLpPayLoad) => {
    const url = `${backendBasePath}/general_ad_lp_seller_users`;

    const payload = JSON.stringify(payloadParams);

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    })
      .then((response) => {
        if (response.status === 200) {
          const cookieExpires = 60 * 60 * 24 * 180;
          setCookie('expiry', response.headers.get('expiry'), { maxAge: cookieExpires, path: '/' });
          setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
          setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
          setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
          setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
          setCookie('login_type', 'email', { maxAge: cookieExpires, path: '/' });
          setTokenValidity('valid');
          return response.json();
        } else {
          return false;
        }
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('payload', payload);
          Sentry.captureException(error);
        });
        return error;
      });
    return result;
  };

  const signUpViaInquiryChatForm = async (payloadParams: signUpViaInquiryChatFormPayLoad) => {
    const url = `${backendBasePath}/inquiry_seller_users`;

    const payload = JSON.stringify(payloadParams);

    const result = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    })
      .then((response) => {
        if (response.status === 200) {
          const cookieExpires = 60 * 60 * 24 * 180;
          setCookie('expiry', response.headers.get('expiry'), { maxAge: cookieExpires, path: '/' });
          setCookie('access-token', response.headers.get('access-token'), { maxAge: cookieExpires, path: '/' });
          setCookie('client', response.headers.get('client'), { maxAge: cookieExpires, path: '/' });
          setCookie('uid', response.headers.get('uid'), { maxAge: cookieExpires, path: '/' });
          setCookie('user-type', 'seller', { maxAge: cookieExpires, path: '/' });
          setCookie('login_type', 'email', { maxAge: cookieExpires, path: '/' });
          setTokenValidity('valid');
          return response.json();
        } else {
          return false;
        }
      })
      .catch((error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('payload', payload);
          Sentry.captureException(error);
        });
        return error;
      });
    return result;
  };

  const sellerServices = cookies['services'];

  return {
    sellerLogin,
    sellerLineLogin,
    checkSellerLoginType,
    tokenValidity,
    setTokenValidity,
    validateToken,
    logout,
    sellerServices,
    updateLeasebackSigningUpUser,
    registerCityLpSeller,
    registerAlLpSeller,
    signUpViaGmoAlLp,
    signUpViaGeneralAdLp,
    signUpViaInquiryChatForm,
  };
};
